<template>
  <div>
    <div class="page-header">
      <h1>{{ $tc("partner", 2) }}</h1>
    </div>

    <div class="card">
      <div class="card-content pt-3">
        <TableFilter :filter="filter"></TableFilter>

        <b-table
          ref="table"
          :current-page="page"
          :data="partners"
          :loading="loading"
          :total="total"
          backend-pagination
          class="is-clickable mt-2"
          hoverable
          paginated
          pagination-simple
          pagination-size="is-small"
          per-page="30"
          @click="
            (e) =>
              this.$router.push({
                name: 'PartnerView',
                params: { id: e.id },
              })
          "
          @page-change="(e) => (page = e)"
        >
          <template #empty>
            {{ loading ? $t("loading") : $t("no_matching_results") }}
          </template>

          <b-table-column :label="$tc('partner_role')">
            <template v-slot="props">
              {{ props.row.partnerRole.name }}
            </template>
          </b-table-column>

          <b-table-column :label="$tc('company')">
            <template v-slot="props">
              {{ props.row.company.name }}
            </template>
          </b-table-column>

          <b-table-column :label="$tc('user')">
            <template v-slot="props">
              <span v-if="props.row.user">
                {{ props.row.user.name }}
              </span>
            </template>
          </b-table-column>

          <b-table-column :label="$tc('contract')">
            <template v-slot="props">
              {{ props.row.contract.startDate | date }} &ndash;
              {{ props.row.contract.endDate | date }}
            </template>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TableFilter from "../../components/TableFilter";

export default {
  name: "PartnerIndex",
  components: {
    TableFilter,
  },
  data() {
    return {
      loading: true,
      page: 1,
      total: null,
      filter: {
        partnerRole: null,
        company: null,
        contract: null,
      },
      partners: [],
    };
  },
  computed: {
    ...mapGetters(["findPartnerRoleByUri"]),
  },
  watch: {
    page() {
      this.load();
    },
    filter: {
      deep: true,
      handler() {
        this.load();
      },
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions(["getPartners"]),
    load() {
      this.loading = true;

      this.getPartners({
        page: this.page,
        partnerRole: this.filter.partnerRole,
        company: this.filter.company,
        contract: this.filter.contract,
      }).then(async ({ items, total }) => {
        for (const i of items) {
          i.partnerRole = this.findPartnerRoleByUri(i.partnerRole);
        }
        this.partners = items;
        this.total = total;

        this.loading = false;
      });
    },
  },
};
</script>
